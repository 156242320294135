$(document).ready(function() {
	if ($('.js-page-controller-item').length) {
		setDefaultFontSize();
	}

	if ($('.js-slider').length) {
		slider();
	}

	if ($('.js-accordion').length) {
		accordionLength();
	}

	if ($('.js-gallery').length) {
		gallery();
	}

	responsiveTable();

	// showLoader();
	// setTimeout(function(){
	//     hideLoader();
	// }, 500);

	if ($('.js-site-message').length) {
		hideSiteMessage();
	}

	AOS.init({
		once : true
	});
});

$(window).on('resize', function() {
	recalculateAccordionLength();
	closeDropdown();
});

//click outside
$(document).on('click', function(event) {
	if (!$(event.target).closest('.js-dropdown-container').length) {
		closeDropdown();
	}
	if (!$(event.target).closest('.js-header-main-nav').length) {
		closeMenu();
	}
});

// on escape keypress
$(document).keyup(function(e) {
	if (e.keyCode == 27) {
		// escape key maps to keycode `27`
		closeDropdown();
		closeMenu();
	}
});

// Sticky nav
$(document).on('click', '.js-sticky-nav-button', function() {
	var $this = $(this),
		stickyNav = $this.parents('.js-sticky-nav');

	stickyNav.addClass('active');
});

$(document).on('click', '.js-sticky-nav-close-button', function() {
	closeStickyNav();
});

// Tabs
$(document).on('click', '.js-tabs-button', function() {
	var $this = $(this);
	if (!$this.hasClass('active')) {
		changeTab($this, $this.index());
	}
});

$(document).on('change', '.js-tabs-select', function() {
	var $this = $(this);
	changeTab($this, $this.prop('selectedIndex'));
});

// Video toggle
$(document).on('click', '.js-video-toggle-content-button', function() {
	var $this = $(this),
		videoSection = $this.parents('.js-video-section'),
		videoToggleContent = videoSection.find('.js-video-toggle-content');

	if (videoToggleContent.hasClass('active')) {
		videoToggleContent.find('iframe').removeAttr('src');
		videoToggleContent.stop(true, true).slideUp();
		videoToggleContent.removeClass('active');
	}
	else {
		videoToggleContent.find('iframe').attr('src', $this.data('video-src'));
		videoToggleContent.stop(true, true).slideDown();
		videoToggleContent.addClass('active');
	}
});

// Dropdowns
$(document).on('click', '.js-dropdown-button', function() {
	var $this = $(this),
		dropdownContainer = $this.parents('.js-dropdown-container');

	if (dropdownContainer.hasClass('active')) {
		dropdownContainer.removeClass('active');
	}
	else {
		closeDropdown();
		dropdownContainer.addClass('active');
	}
});

// Header dropdown in sandwich menu
$(document).on('click', '.js-header-dropdown-button', function() {
	var $this = $(this),
		headerNavItem = $this.parents('.js-header-main-nav-item'),
		headerDropdown = headerNavItem.find('.js-header-dropdown');

	if (headerNavItem.hasClass('dd-opened')) {
		headerNavItem.removeClass('dd-opened');
		headerDropdown.stop(true, true).slideUp();
	}
	else {
		$('.js-header-main-nav-item.dd-opened').find('.js-header-dropdown').stop(true, true).slideUp();
		$('.js-header-main-nav-item.dd-opened').removeClass('dd-opened');
		headerNavItem.addClass('dd-opened');
		headerDropdown.stop(true, true).slideDown();
	}
});

// Font resize
$(document).on('click', '.js-font-size-controller-button', function() {
	var $this = $(this),
		fontSizeController = $this.parents('.js-font-size-controller'),
		currentFontSize = parseInt(fontSizeController.data('font-size')),
		newFontSize = currentFontSize,
		resizableContent = $('.js-resizable-content');

	if ($this.hasClass('js-font-size-controller-button--increase')) {
		newFontSize += 1;
	}
	else if ($this.hasClass('js-font-size-controller-button--decrease')) {
		newFontSize -= 1;
	}
	fontSizeController.data('font-size', newFontSize).attr('data-font-size', newFontSize);

	if (newFontSize == 20) {
		$('.js-font-size-controller-button--increase').attr('disabled', 'disabled');
	}
	else if (newFontSize == 13) {
		$('.js-font-size-controller-button--decrease').attr('disabled', 'disabled');
	}
	else {
		$('.js-font-size-controller-button--increase').removeAttr('disabled');
		$('.js-font-size-controller-button--decrease').removeAttr('disabled');
	}

	resizableContent.css('font-size', '' + newFontSize + 'px');
});

//  Print page
$(document).on('click', '.js-print-page-button', function() {
	window.print();
});

function setDefaultFontSize() {
	var fontSize = 16;

	if ($(window).innerWidth() <= 768 && $(window).innerWidth() > 480) {
		fontSize = 15;
	}
	else if ($(window).innerWidth() <= 480) {
		fontSize = 14;
	}
	$('.js-font-size-controller').attr('data-font-size', fontSize);
}

function hideSiteMessage() {
	$('.js-site-message').delay(3000).fadeOut();
}

function closeDropdown() {
	if ($('.js-dropdown-container.active').length) {
		$('.js-dropdown-container.active').removeClass('active');
	}
}

function closeStickyNav() {
	$('.js-sticky-nav.active').removeClass('active');
}

function changeTab(tabNav, tabIndex) {
	var tabs = tabNav.parents('.js-tabs'),
		tabsSelect = tabs.find('.js-tabs-select option'),
		tabsButton = tabs.find('.js-tabs-button'),
		tabsContentItem = tabs.find('.js-tabs-content-item');

	tabs.find('.js-tabs-button.active').removeClass('active');
	tabsButton.eq(tabIndex).addClass('active');
	tabsContentItem.stop(true, true).slideUp('fast');
	tabsContentItem.eq(tabIndex).stop(true, true).slideDown('fast');
	tabsSelect.eq(tabIndex).prop('selected', true);
}

// Cookies
$(document).on('click', '.js-cookies-button', function() {
	$(this).parents('.js-cookies').remove();
});

// Sandwich button
$(document).on('click', '.js-sandwich-button', function() {
	var $this = $(this),
		headerMainNav = $this.parents('.js-header-main-nav');

	headerMainNav.toggleClass('menu-opened');
});

// Accordion
$(document).on('click', '.js-accordion-main-button', function() {
	var $this = $(this),
		accordionItem = $this.parents('.js-accordion-item'),
		accordionContent = accordionItem.find('.js-accordion-content');

	if (!accordionItem.hasClass('active')) {
		accordionItem.addClass('active');
		accordionContent.stop(true, true).delay(250).slideDown('fast', function() {});
	}
	else {
		accordionContent.stop(true, true).slideUp('fast', function() {
			accordionItem.removeClass('active');
		});
	}
});

// Еxpand accordion text
$(document).on('click', '.js-accordion-expand-button', function() {
	var $this = $(this),
		accordionItem = $this.parents('.js-accordion-item'),
		accordionText = accordionItem.find('.js-accordion-text');

	accordionItem.data('real-height', accordionText.prop('scrollHeight'));

	if (accordionItem.hasClass('expanded-content')) {
		accordionText.animate(
			{
				'max-height' : '' + accordionItem.data('fixed-height') + 'px'
			},
			300
		);
		accordionItem.removeClass('expanded-content');

		if ($('html').hasClass('touch')) {
			$('html, body').animate(
				{
					scrollTop : accordionItem.offset().top
				},
				300
			);
		}
	}
	else {
		accordionItem.data('fixed-height', accordionText.height());
		accordionText.animate(
			{
				'max-height' : '' + accordionItem.data('real-height') + 'px'
			},
			300
		);
		accordionItem.addClass('expanded-content');
	}
});

// Dictionary
$(document).on('click', '.js-alphabet-letter', function() {});

function accordionLength() {
	$('.js-accordion').each(function() {
		var accordion = $(this);
		accordion.find('.js-accordion-item').each(function() {
			var accordionItem = $(this),
				accordionText = accordionItem.find('.js-accordion-text'),
				accordionContent = accordionItem.find('.js-accordion-content'),
				accordionContentPreviousCss = accordionContent.attr('style');

			accordionContent.css({
				position   : 'absolute',
				visibility : 'hidden',
				display    : 'block'
			});

			var accordionTextRealHeight = accordionText.prop('scrollHeight'),
				accordionTextFixedHeight = parseFloat(accordionText.css('max-height'));

			accordionItem.data('fixed-height', accordionTextFixedHeight);
			accordionItem.data('real-height', accordionTextRealHeight);
			if (accordionTextRealHeight > accordionTextFixedHeight) {
				accordionItem.addClass('show-expand-button');
			}
			else {
				accordionItem.removeClass('show-expand-button');
			}

			accordionContent.attr('style', accordionContentPreviousCss ? accordionContentPreviousCss : '');
		});
	});
}

function recalculateAccordionLength() {
	if ($('.js-accordion').length) {
		$('.js-accordion').each(function() {
			var accordion = $(this);
			accordion.find('.js-accordion-item').each(function() {
				var accordionItem = $(this),
					accordionContent = accordionItem.find('.js-accordion-content'),
					accordionText = accordionItem.find('.js-accordion-text'),
					accordionTextFixedHeight = accordionItem.data('fixed-height'),
					accordionTextRealHeight = 0;

				if (accordionContent.is(':visible')) {
					if (accordionItem.hasClass('expanded-content')) {
						accordionTextRealHeight = accordionText.height();

						if (accordionTextFixedHeight >= accordionTextRealHeight) {
							accordionItem.removeClass('expanded-content show-expand-button');
							accordionText.css({
								'max-height' : '' + accordionTextFixedHeight + 'px'
							});
						}
					}
					else {
						accordionTextRealHeight = accordionText.prop('scrollHeight');

						if (accordionTextRealHeight > accordionTextFixedHeight) {
							accordionItem.addClass('show-expand-button');
						}
						else {
							accordionItem.removeClass('show-expand-button');
						}
					}
				}
				else {
					var accordionContentPreviousCss = accordionContent.attr('style');

					accordionContent.css({
						position   : 'absolute',
						visibility : 'hidden',
						display    : 'block'
					});

					accordionTextRealHeight = accordionText.prop('scrollHeight');

					if (accordionItem.hasClass('expanded-content')) {
						if (accordionTextFixedHeight >= accordionTextRealHeight) {
							accordionItem.removeClass('expanded-content show-expand-button');
							accordionText.css({
								'max-height' : '' + accordionTextFixedHeight + 'px'
							});
						}
					}
					else {
						if (accordionTextRealHeight > accordionTextFixedHeight) {
							accordionItem.addClass('show-expand-button');
						}
						else {
							accordionItem.removeClass('show-expand-button');
						}
					}
					accordionContent.attr('style', accordionContentPreviousCss ? accordionContentPreviousCss : '');
				}
			});
		});
	}
}

function slider() {
	$('.js-slider').each(function() {
		$(this).slick({
			dots           : true,
			arrows         : false,
			infinite       : false,
			speed          : 300,
			slidesToShow   : 2,
			slidesToScroll : 1,
			responsive     : [
				{
					breakpoint : 768,
					settings   : {
						slidesToShow   : 1,
						slidesToScroll : 1
					}
				}
			]
		});
	});
}
// Show loader
function showLoader() {
	$('.js-loader').addClass('active');
}

// Hide loader
function hideLoader() {
	$('.js-loader').removeClass('active');
}

function closeMenu() {
	$('.js-header-main-nav.menu-opened').removeClass('menu-opened');
}

function gallery() {
	$('.js-gallery').lightGallery({
		download           : false,
		fullScreen         : false,
		zoom               : false,
		share              : false,
		autoplayControls   : false,
		showThumbByDefault : false,
		speed              : 350,
		counter            : false
	});
}

function responsiveTable() {
	if ($('table').length) {
		$('table').each(function() {
			$(this).wrap('<div class="scrollable-content"></div>');
		});
	}
}
